<template>
  <div>
    <top-nav></top-nav>
    <div class="home">
    <!-- <div class="homepage"> -->
      <div class="part1BookDemo">
        <div class="leftContent">
          <div class="part1title">
            Arcanite Partners
          </div>
          
          <div class="part1content">
            Are you a financial planner, mortgage broker or conveyancer? Arcanite Partners offers a platform for professional associates in the property industry to collaborate with our client base consisting of property developers and real estate agents.
            <br>
            <br>
            Contact us to expand your professional network and advance your business opportunities by joining Arcanite Partners today.

          </div>
          <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
            return false;">
            <button class="part1Button"><div class="bottomText">Book A Demo</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
            </a>
        </div>
        <div class="rightContent">
          <img src="./../../assets/imgs/pc/arcanitePartner/p1.png" class="p1"/>
        </div>
        <!-- <div class="bottomContent">
            <recognised></recognised>
            <div class="empty">
                <trusted></trusted>
            </div>
        </div> -->
        <br>
      </div>

      <div class="part2RecoginizedIcons">
        <div class="part2-topcontent">
          <trusted></trusted>
        </div>
        <div class="part2-bottomcontent">
            <recognised></recognised>
        </div>
      </div>
      <div class="part3PicsText">
        <div class="part3Middle">
          <div class="leftContent">
            <img src="./../../assets/imgs/pc/arcanitePartner/p2.png" class="pic2" />
          </div>
          <div class="rightContent" >
            <div class="p1">
              <img src="./../../assets/imgs/pc/arcanitePartner/minicons1.png" class="pic" />
              <p>Managing all your leads in one platform</p>
              <div class="content">
                  Arcanite Partners Portal is a web-based platform designed and developed to track leads between referral partners and facilitate digital loan  submissions for Arcanite’s finance product Equimate. The portal provides the dedicated lead generation funnel via the fact find affiliate links. The inbuilt links provide the users more power to generate leads via social media and from referral partners, with all information merging into the Arcanite’s CRM.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part5Comments">
        <other-product></other-product>
      </div>
    </div>
    <!-- </div> -->
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data() {
      return{
      }
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  },
  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "foot-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./RecognisedPart.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home{
  width: 100vw;
  // width: 1210px;
  display: inline-block;
  text-align: center;
  position: relative;
  font-family: Poppins;
  overflow-x:hidden;
}

// .homepage{
//   margin: auto;
//   width:1210px;
//   display: block;
//   position: relative;
//   z-index: -100;
//   overflow-x:hidden;
// }
.part1BookDemo{
  overflow:hidden;
  // height: 1100px;
  width: 1210px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  // margin: 0 0 3px;
  // padding: 0 0 13px;
  background-color: white;
  z-index: 0;
  position: relative;
  justify-content: space-around;
  .p1{
    width: 557.2px;
    height: 348.2px;
    position:absolute;
    top:178px;
    right:10px;
    z-index: 1;
  }
  .p11{
    width: 237px;
    height: 421px;
    position:absolute;
    bottom:0;
    right:0;
    z-index: 2;
  }
  .p12{
    width: 22px;
    height: 22px;
    flex-grow: 0;
  }
  .dogs{
    width: 65px;
    height: 50px;
  }
  .p13{
    position:absolute;
    bottom:110px;
    left:-30px;
    z-index: 0;
  }
  .p14{
    position:absolute;
    top:-55px;
    right:40px;
    z-index: 0;
  }
  .leftContent{
    width: 520px;
    height: 606px;
    padding-top: 58px;
    padding-left: 10px;
    padding-bottom: 30px;
  }
  .rightContent{
    width:646.8px;
    height:100%;
    position:absolute;
    bottom:0;
    right:10px;
    z-index: -99;
  }
  .part1title{
    margin-top: 116px;
    margin-bottom: 30px;
    width: 481px;
    height: 62px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 56px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
	white-space:nowrap;
  }

  .part1content{
    width: 500px;
    // height: 60px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
  }
  .bottomContent{
    display:flex;
    margin-left: 10px;
    gap: 50px;
  }
  .empty{
      // margin-left: 70px;
  }

  .part1Button{
    margin-top: 50px;
    width: 175px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
  }
  .part1Button:hover{
    background-color: #096dd9;
  }
      .part1Button:active{
    background-color: #0757ae;
  }
  .bottomText{
    width: 111px;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    white-space: nowrap;
  }
}

.part2RecoginizedIcons{
  height: 350px;
  margin: 0px auto;
  width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  // padding-left: 20px;
  gap: 40px;
}

.part3PicsText{
  overflow-x:hidden;
  height: 716px;
  margin-top: 82px;
  width: 100vw;
  display: flex;
  position: relative;
  background: #f7f9fb;
  z-index: -999;
  .part3Middle{
    width: 1210px;
    height: 716px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: space-around;
    .leftContent{
      position:relative;
      left:-60px;
      display: inline-block;
      width: 747px;
      height: 716px;
      .pic2{
          width: 797px;
          height: 546.8px;
          position: absolute;
          left: 0;
          bottom: 10px;
      }
    }
    .rightContent{
      position:relative;
      left:-60px;
      display: inline-block;
      width: 454px;
      // margin-left: 10px;
      height: 597px;
      padding-right: 20px;
      }
      .p1{
        // margin: 10px;
        position:absolute;
        top: 20%;
        text-align: left;
        .pic1{
          width: 80px;
          height: 80px;
        }
        p{
          margin-left: 10px;
          position:relative;
          font-family: Poppins-Bold;
          font-size: 36px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          margin-bottom: 20px;
        }
        .content{
          margin-left: 10px;
          width: 490px;
          height: 240px;
          flex-grow: 0;
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
    }
  }
}


.part5Comments{
  margin:auto;
  width: 1210px;
  height: 883px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.pic{
    width: 91px;
    height: 91px;
}

</style>